import htttp from './http';

class ExchangeGoodsApi {
  static exchangeGoodsList() {
    return htttp.post('/zp-mobile/v1/credit/exchange/goods/list');
  }
  static createExchangeGoods() {
    return htttp.post('/zp-mobile/v1/credit/exchange/goods/create');
  }
  static deleteExchangeGoods() {
    return htttp.post('/zp-mobile/v1/credit/exchange/goods/delete');
  }
  static detailExchangeGoods() {
    return htttp.post('/zp-mobile/v1/credit/exchange/goods/detail');
  }
  static updateExchangeGoods() {
    return htttp.post('/zp-mobile/v1/credit/exchange/goods/update');
  }
  static exchangeRecordList(param) {
    return htttp.post('/zp-transaction/v1/credit/exchange-goods-record-list', param);
  }
}

export default ExchangeGoodsApi;
