<template>
  <div>
    <div style="display: flex">
      <el-button type="primary" @click="add(null)">创建</el-button>
    </div>
    <el-table
      :data="list"
      border
      :max-height="maxHeight"
      style="margin-top: 20px"
    >
      <el-table-column prop="spuId" label="商品ID"></el-table-column>
      <el-table-column prop="goodsName" label="商品名称"></el-table-column>
      <el-table-column prop="creditPoints" label="消耗积分"></el-table-column>
      <el-table-column prop="creditPoints" label="消耗积分"></el-table-column>
      <el-table-column prop="userAddress.username" label="收件人"></el-table-column>
      <el-table-column prop="userAddress.phone" label="手机号"></el-table-column>
      <el-table-column prop="address" label="地址"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="detail(scope.row)"
            >详情</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import ExchangeGoodsApi from '@/request/ExchangeGoods';
import dayjs from "dayjs";
export default {
  data() {
    return {
      maxHeight: document.documentElement.clientHeight - 250,
      list: [],
      page: 1,
      totalRows: 0,
      dialogVisible: false,
      currentCourse: null,
      inputPhone: "",
      orderSource: "",
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      ExchangeGoodsApi.exchangeRecordList({
        pageNo: this.page,
        pageSize: 20,
        isBackend: true
      }).then((res) => {
        if (res.success) {
          this.list = res.data.map(item => {
            return {
              ...item,
              address: item.userAddress.province + item.userAddress.city + item.userAddress.strict + item.userAddress.address
            }
          });
          this.totalRows = res.totalRows;
        }
      });
    },
    handleChangePage(val) {
      this.page = val;
      this.loadData();
    },
    formatTime(val) {
      return dayjs(val).format("YYYY-MM-DD HH:mm");
    },
  },
};
</script>

<style></style>
